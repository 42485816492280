<template>
  <div class="home">
    <div class="floor-one-wrap">
      <div class="box-one flex-item-center">
        <!-- <div class="text1">即刻互动</div>
        <div class="text2">精彩无限</div> -->
        <img class="img1" src="../../assets/image/homeText1.png" alt="">
      </div>
      <div class="box-two flex-center">
        <!-- <div @click="changeDownload(0)" class="inner1"
          :style="{ background: downloadIndex == 0 ? 'rgba(255, 255, 255, 0.8)' : 'transparent' }">
          <img :style="{ opacity: downloadIndex == 0 ? 1 : 0 }" class="code-icon" :src="codeIcon" alt="" />
          <span :style="{ opacity: downloadIndex == 0 ? 1 : 0 }" class="text1">扫描二维码下载</span>
          <div class="group1 flex-center">
            <img class="apple-icon" :src="appleIcon" alt="" />
            <span class="text2" :style="{ color: downloadIndex == 0 ? '#131313' : '#F4F4F5' }">iOS</span>
          </div>
        </div> -->
        <div @click="changeDownload(1)" class="inner1 inner3"
          :style="{ background: downloadIndex == 1 ? 'rgba(255, 255, 255, 0.8)' : 'transparent' }">
          <img :style="{ opacity: downloadIndex == 1 ? 1 : 0 }" class="code-icon" :src="codeIcon" alt="" />
          <!-- <canvas ref="qrCodeCanvas" class="code-icon"></canvas> -->
          <span :style="{ opacity: downloadIndex == 1 ? 1 : 0 }" class="text1">扫描二维码下载</span>
          <!-- <div class="group1 flex-center">
            <img class="android-icon" :src="androidIcon" alt="" />
            <span class="text2" :style="{ color: downloadIndex == 1 ? '#131313' : '#F4F4F5' }">Android</span>
          </div> -->
        </div>
        <!-- <div class="inner2 flex-center">
          <img class="android-icon" :src="androidIcon" alt="" />
          <span class="text3">Android</span>
        </div> -->
      </div>
      <div class="box-three flex-item-center">
        <div class="inner1 flex-center">
          <img class="bottom-icon" :src="bottomIcon" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="floor-two-wrap">
      <div class="box-one">
        <div class="inner1">让<span class="text1">连接</span>更紧密，</div>
        <div class="inner2">让分享更精彩</div>
        <div class="group-one" @click="navSwiperItem(1)">
          <div class="group-inner1">
            <div :class="swiperIndex == 0 ? 'circle' : 'circle1'" class="circle"></div>
            <div class="line"></div>
          </div>
          <div class="group-inner2">
            <div :class="swiperIndex == 0 ? 'text1' : 'text3'">社交互动</div>
            <div class="text2">
              在一个平台上完成多种社交活动，链接品牌与用户
            </div>
          </div>
        </div>
        <div class="group-one" @click="navSwiperItem(2)">
          <div class="group-inner1">
            <div :class="swiperIndex == 1 ? 'circle' : 'circle1'" class="circle"></div>
            <div class="line"></div>
          </div>
          <div class="group-inner2">
            <div :class="swiperIndex == 1 ? 'text1' : 'text3'">创新形态</div>
            <div class="text2">
              在交友的同时，享受到购物的乐趣
            </div>
          </div>
        </div>
        <div class="group-one" @click="navSwiperItem(3)">
          <div class="group-inner1">
            <div :class="swiperIndex == 2 ? 'circle' : 'circle1'" class="circle"></div>
            <div class="line"></div>
          </div>
          <div class="group-inner2">
            <div :class="swiperIndex == 2 ? 'text1' : 'text3'">畅享沟通</div>
            <div class="text2">
              让你的声音被听见，让你的才情被赞赏
            </div>
          </div>
        </div>
        <div class="group-one" @click="navSwiperItem(4)">
          <div class="group-inner1">
            <div :class="swiperIndex == 3 ? 'circle' : 'circle1'" class="circle"></div>
          </div>
          <div class="group-inner2">
            <div :class="swiperIndex == 3 ? 'text1' : 'text3'">精彩纷呈</div>
            <div class="text2">
              分享各类生活好物，让你的生活更加便利和丰富
            </div>
          </div>
        </div>
      </div>
      <div class="box-two">
        <div class="box1 swiper-container">
          <div class="swiper-wrapper">
            <img v-for="(item, i) in swiperList" :key="i" :src="item.img" class="img1 swiper-slide" alt="">
          </div>

        </div>

        <img :src="circle1" class="img2" alt="">
      </div>
    </div> -->
    <div class="floor-two-newWrap">
      <div class="group-one">

        <div class="group-inner2">
          <div class="text1">社交互动</div>
          <div class="text2">
            在一个平台上完成多种社交活动，链接品牌与用户
          </div>
        </div>
        <div class="group-inner3">
          <img class="img1" :src="swiperList[0].img" alt="">
        </div>
      </div>
      <div class="group-one">

        <div class="group-inner2">
          <div class="text1">创新形态</div>
          <div class="text2">
            在交友的同时，享受到购物的乐趣
          </div>
        </div>
        <div class="group-inner3">
          <img class="img1" :src="swiperList[1].img" alt="">
        </div>
      </div>
      <div class="group-one">

        <div class="group-inner2">
          <div class="text1">畅享沟通</div>
          <div class="text2">
            让你的声音被听见，让你的才情被赞赏
          </div>
        </div>
        <div class="group-inner3">
          <img class="img1" :src="swiperList[2].img" alt="">
        </div>
      </div>
      <div class="group-one" style="margin-bottom:0;">

        <div class="group-inner2">
          <div class="text1">精彩纷呈</div>
          <div class="text2">
            分享各类生活好物，让你的生活更加便利和丰富
          </div>
        </div>
        <div class="group-inner3">
          <img class="img1" :src="swiperList[3].img" alt="">
        </div>
      </div>
    </div>
    <!-- <div id="qrCode" ref="qrCodeDiv" style="position: fixed;top:-500vh;"></div> -->
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
import { selOneVersion } from "@/api/index.js";

import QRCode from 'qrcode';
export default {
  name: "Home",
  components: {},
  data() {
    return {
      logoIcon: require("/src/assets/image/logoIcon.png"),
      codeIcon: require("/src/assets/image/code.png"),
      appleIcon: require("/src/assets/image/appleIcon.png"),
      androidIcon: require("/src/assets/image/androidIcon.png"),
      bottomIcon: require("/src/assets/image/bottomIcon.png"),
      phoneBanner1: require("/src/assets/image/phoneBanner1.png"),
      circle1: require("/src/assets/image/circle1.png"),
      downloadIndex: 1,
      swiperList: [{ img: require('../../assets/image/swiper1.png') }, { img: require('../../assets/image/swiper2.png') }, { img: require('../../assets/image/swiper3.png') }, { img: require('../../assets/image/swiper4.png') }],
      swiperIndex: 0,
      mySwiper: null,
      apkUrl:'',
      qrcode:null
    };
  },
  mounted() {
    var swiper = new Swiper('.swiper-container', {
      direction: 'vertical',
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      grabCursor: true,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
      on: {
        slideChange: (res) => {

          if (swiper) {
            console.log(swiper.realIndex, '变化');
            this.swiperIndex = swiper.realIndex;
            // if(swiper.activeIndex==(this.swiperList.length)){
            //   this.swiperIndex=0;
            // }else if(swiper.activeIndex==(this.swiperList.length+1)){
            //   this.swiperIndex=1;
            // }else{
            //   this.swiperIndex = swiper.activeIndex;
            // }

          }
          // this.swiperIndex = swiper.activeIndex;
          // this.swiperLeft = this.swiperIndex * 6 + this.swiperIndex * 0.2 + 3
        },
      },
    });
    this.mySwiper = swiper;
    console.log(this.mySwiper, 'this.mySwiper')
  },
  created(){
    // this.selOneVersion();
  },
  methods: {
    generateQRCode () {
      const canvas = this.$refs.qrCodeCanvas; // 获取 canvas 元素的引用
      const text = this.apkUrl; // 二维码的文本内容
 
      QRCode.toCanvas(canvas, text, (error) => {
        if (error) {
          console.error('生成二维码失败', error);
        }
      });
    },

    selOneVersion() {
      selOneVersion({
        userId: "", //uni.getStorageSync('userInfo').userId
					version: ""
      }).then((res) => {
        // console.log(res, "res");
        if (res.retCode == 0) {
          let apkUrl = res.data.apkUrl;
          this.apkUrl=apkUrl;
          console.log(apkUrl,'downloadUrl')
          this.generateQRCode();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    navSwiperItem(index) {
      this.mySwiper.slideTo(index, 1000, false);
    },
    testVant() { },
    changeTab(index) {
      this.tabIndex = index;
    },
    changeDownload(index) {
      this.downloadIndex = index;
    }
  },
};
</script>
<style lang="scss" scoped>
.home {
  padding-bottom: 0.08rem;
}

.floor-one-wrap {
  width: 100%;
  padding-bottom: 1rem;
  background: #00b386;
  border-radius: 0px 0px 0.32rem 0.32rem;

  .head-wrap {
    padding: 0 0.34rem;

    .logo-icon {
      width: 0.42rem;
      height: 0.38rem;
      margin-right: 0.8rem;
    }

    .tab-wrap {
      .tab-item {
        height: 0.72rem;
        padding: 0 0.44rem;
        font-size: 0.24rem;
        color: #fff;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        width: auto;

        .tab-line {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 0.48rem;
          height: 0.03rem;
          background: #ffffff;
          bottom: 0;
        }
      }
    }
  }

  .box-one {
    padding: 1.5rem 0 1rem;

    .img1 {
      width: 2.5rem;
      height: 1.62rem;
    }

    .text1,
    .text2 {
      font-size: 0.5rem;
      color: #fff;
    }

    .text1 {
      margin-bottom: 0.2rem;
    }
  }

  .box-two {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .inner1 {
      width: 1.8rem;
      height: 2.22rem;//2.62
      background: rgba(255, 255, 255, 0.8);
      border-radius: 0.08rem;
      // margin-right: 0.63rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 0.24rem;
      // cursor: pointer;

      .code-icon {
        width: 1.3rem !important;
        height: 1.3rem !important;
      }

      .text1 {
        font-size: 0.18rem;
        color: #131313;
        font-weight: 500;
        margin: 0.16rem 0 0.14rem;
      }

      .group1 {
        .apple-icon {
          width: 0.3rem;
          height: 0.36rem;
          margin-right: 0.14rem;
        }

        .android-icon {
          width: 0.41rem;
          height: 0.24rem;
          margin-right: 0.14rem;
        }

        .text2 {
          font-size: 0.24rem;
          color: #131313;
          font-weight: 500;
        }
      }
    }

    .inner2 {
      height: 0.5rem;
      padding: 0 0.3rem;
      position: relative;
      bottom: 0.08rem;

      .android-icon {
        width: 0.41rem;
        height: 0.24rem;
        margin-right: 0.14rem;
      }

      .text3 {
        font-size: 0.24rem;
        color: #f4f4f5;
        font-weight: 500;
      }

      .inner3 {
        margin-left: 0.6rem;
      }
    }
  }

  .box-three {
    width: 100%;
    margin-top: 0.9rem;

    .inner1 {
      padding: 0.1rem 0.3rem;

      .bottom-icon {
        width: 0.26rem;
        height: 0.21rem;
      }
    }
  }
}

.floor-two-wrap {
  padding: 0.6rem 0.3rem 0.42rem;
  display: flex;

  .box-one {
    padding-top: 1.17rem;
    position: relative;
    z-index: 10;
    width: 6.12rem;

    .inner1,
    .inner2 {
      font-size: 0.42rem;
      color: #131313;
      font-weight: 600;

      .text1 {
        color: #00b386;
      }
    }

    .inner2 {
      margin: 0.13rem 0 0.83rem;
    }

    .group-one {
      display: flex;
      margin-bottom: 0.12rem;
      cursor: pointer;

      .group-inner1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0.16rem;
        margin-right: 0.2rem;
        position: relative;
        top: 0.115rem;

        .circle {
          width: 0.07rem;
          height: 0.1rem;
          background: #00b386;
          border-radius: 0.04rem;
          flex-shrink: 0;
          margin-bottom: 0.09rem;
        }

        .circle1 {
          background: #E6E6E6;
        }

        .line {
          width: 0.01rem;
          height: 0.84rem;
          opacity: 0.24;
          border: 1px solid #979797;
        }
      }

      .group-inner2 {

        .text1,
        .text3 {
          font-size: 0.28rem;
          color: #131313;
          font-weight: 500;
          margin-bottom: 0.22rem;
        }

        .text3 {
          color: #B9B9B9;
        }

        .text2 {
          font-size: 0.16rem;
          color: #979797;
          font-weight: 500;
        }
      }
    }
  }

  .box-two {
    width: 3.64rem;
    position: relative;

    .box1 {
      width: 3.64rem;
      height: 7.49rem;
      position: relative;
      z-index: 10;

      .img1 {
        width: 3.64rem;
        height: 7.49rem;
        position: relative;
        z-index: 10;
      }
    }



    .img2 {
      position: absolute;
      left: -3.17rem;
      bottom: 0.14rem;
      width: 5.46rem;
      height: 6.12rem;
    }
  }
}

.floor-two-newWrap {
  padding: 0.4rem 0 0rem;

  .group-one {
    margin-bottom: 0.6rem;

    .group-inner2 {
      display: flex;
      flex-direction: column;
      align-items: center;

      .text1,
      .text3 {
        font-size: 0.4rem;
        color: #131313;
        font-weight: 600;
        margin-bottom: 0.22rem;
        text-align: center;
      }

      .text3 {
        color: #B9B9B9;
      }

      .text2 {
        font-size: 0.26rem;
        color: #979797;
        font-weight: 500;
        text-align: center;
      }
    }

    .group-inner3 {
      display: flex;
      justify-content: center;
      margin-top: 0.4rem;

      .img1 {
        width: 4.4rem;
        height: auto;
      }
    }
  }
}

.footer-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.14rem;

  .floor-one {
    display: flex;
    align-items: center;
    justify-content: center;

    .text1 {
      cursor: pointer;
      padding: 0.1rem 0.1rem;
      color: #131313;
      font-size: 0.3rem !important;
    }
  }

  .floor-two {
    display: flex;
    align-items: center;
    margin-top: 0.38rem;
    font-size: 0.2rem;
    color: #979797;

    .text1 {
      padding: 0 0.03rem;
      color: #979797;
      font-size: 0.14rem;
    }
  }

  .floor-three {
    font-size: 0.14rem;
    color: #979797;
    margin: 0.07rem 0;
  }

  .floor-four {
    font-size: 0.14rem;
    color: #979797;
  }
}

.content-box {
  width: 9.6rem;
  height: 50px;
  background: #faf;
  color: #fff;
  text-align: center;
}
</style>
